import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkGfm from "remark-gfm";
import { Root, Heading, Text } from "mdast";
import { formatSlug } from "./format";

export interface TOCItem {
  depth: number;
  text: string;
  id: string;
}

export const extractMarkdownTitles = (markdownText: string): TOCItem[] =>
  (
    (unified().use(remarkParse).use(remarkGfm).parse(markdownText) as Root)
      .children as Heading[]
  )
    .filter((node) => node.type === "heading")
    .map((node) => ({
      depth: node.depth,
      text: node.children
        .filter((child): child is Text => child.type === "text")
        .map((child) => child.value)
        .join(""),
      id: formatSlug(
        node.children
          .filter((child): child is Text => child.type === "text")
          .map((child) => child.value)
          .join(""),
      ),
    }));
