import { graphql, useFragment } from "react-relay";
import { useBlogArticlesPageQuery } from "./loaders/BlogArticlesPage";

import { cn } from "../utils/tailwind";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import Button from "../components/Button";

import { DESKTOP_MEDIA_QUERY } from "../common/constants";
import { useMediaQuery } from "../utils/hooks";
import { BlogArticleCard } from "../components/BlogArticleCard";
import {
  BlogArticlesPageArticlesFragment$data,
  BlogArticlesPageArticlesFragment$key,
} from "./__generated__/BlogArticlesPageArticlesFragment.graphql";
import {
  BlogArticlesPageLatestArticlesFragment$data,
  BlogArticlesPageLatestArticlesFragment$key,
} from "./__generated__/BlogArticlesPageLatestArticlesFragment.graphql";

const BlogArticlesPageArticlesFragment = graphql`
  fragment BlogArticlesPageArticlesFragment on Forum
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    topics(first: $count, after: $cursor, order: $order) {
      edges {
        node {
          id
          ...BlogArticleCardFragment
        }
      }
    }
  }
`;

const BlogArticlesPageLatestArticlesFragment = graphql`
  fragment BlogArticlesPageLatestArticlesFragment on Forum
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 3 }
    order: { type: "VotableOrder", defaultValue: NEWEST }
  ) {
    latestTopics: topics(first: $count, order: $order) {
      edges {
        node {
          id
          ...BlogArticleCardFragment
        }
      }
    }
  }
`;

export default function BlogsPage() {
  const intl = useIntl();
  const {
    query: { forumBySlug: forum, viewer },
  } = useBlogArticlesPageQuery();

  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "blog not found",
        })}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <span className="text-indigo text-sm font-semibold leading-tight">
          <FormattedMessage defaultMessage="Our blog" />
        </span>
        <h1 className="text-gray-950 text-3xl font-semibold leading-10 -ml-1">
          <FormattedMessage defaultMessage="The latest from Quantum world" />
        </h1>
        <h2 className="text-gray-600 text-xl font-normal leading-8">
          <FormattedMessage defaultMessage="The latest quantum news, interviews, technologies, and resources." />
        </h2>
      </div>
      {viewer?.canCreateArticle && (
        <Button kind="primary" to="/blog/new">
          <FormattedMessage defaultMessage="Create Article" />
        </Button>
      )}
      <div className="flex flex-col gap-24 mt-12">
        <div className="flex flex-col gap-4">
          <p className="text-gray-950  text-xl font-semibold leading-loose">
            <FormattedMessage defaultMessage="Recent blog posts" />
          </p>
          <BlogList blog={forum} kind="latest" />
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-gray-950  text-xl font-semibold leading-loose">
            <FormattedMessage defaultMessage="All blog posts" />
          </p>
          <BlogList blog={forum} kind="all" />
        </div>
      </div>
    </>
  );
}

interface BlogListProps {
  blog:
    | BlogArticlesPageArticlesFragment$key
    | BlogArticlesPageLatestArticlesFragment$key;
  kind: "all" | "latest";
}

function BlogList({ blog: blogFragment, kind }: BlogListProps) {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const blog = useFragment(
    kind === "all"
      ? BlogArticlesPageArticlesFragment
      : BlogArticlesPageLatestArticlesFragment,
    blogFragment,
  );

  function getTopicEdges() {
    switch (kind) {
      case "latest":
        return (blog as BlogArticlesPageLatestArticlesFragment$data)
          .latestTopics?.edges;
      case "all":
        return (blog as BlogArticlesPageArticlesFragment$data).topics?.edges;
    }
  }

  return (
    <div
      className={cn(
        "grid-cols-1 gap-24",
        kind === "latest"
          ? "grid md:gap-x-6 md:gap-y-2 md:grid-cols-[1fr_1fr_2fr]"
          : "grid md:gap-y-12 md:gap-x-8 md:grid-cols-3",
      )}
    >
      {getTopicEdges().map(({ node }, index) => (
        <div
          key={node.id}
          className={cn(
            "sm:w-full",
            kind === "latest" && index === 0 && "md:col-span-2 md:row-span-2",
          )}
        >
          <BlogArticleCard
            article={node}
            layout={
              kind === "latest" && index !== 0 && isDesktop ? "row" : "column"
            }
          />
        </div>
      ))}
    </div>
  );
}
