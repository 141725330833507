/**
 * @generated SignedSource<<a318b1eafeb0ce342aa086b8fdd42661>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticlePageFragment$data = {
  readonly article: {
    readonly blurHash: string | null | undefined;
    readonly image: any | null | undefined;
    readonly metadata: {
      readonly description: string | null | undefined;
      readonly title: string | null | undefined;
    } | null | undefined;
    readonly shortDescription: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContributorsFragment">;
  } | null | undefined;
  readonly author: {
    readonly username: string;
  };
  readonly createdAt: string;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly viewerCanEdit: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"TopicCommentsFormTopicFragment" | "TopicCommentsFragment" | "TopicHelmetFragment" | "VoteDisplayFragment">;
  readonly " $fragmentType": "BlogArticlePageFragment";
};
export type BlogArticlePageFragment$key = {
  readonly " $data"?: BlogArticlePageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticlePageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticlePageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blurHash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeoMetadata",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContributorsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_ARTICLE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_ARTICLE\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicCommentsFormTopicFragment"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "NEWEST"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TopicCommentsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoteDisplayFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicHelmetFragment"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "012a70f06cf35f8332e45597f6f359fe";

export default node;
