/**
 * @generated SignedSource<<58da7c0d5a494d963b361c6be2c08204>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleCardFragment$data = {
  readonly article: {
    readonly blurHash: string | null | undefined;
    readonly image: any | null | undefined;
    readonly shortDescription: string;
  } | null | undefined;
  readonly author: {
    readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
  };
  readonly createdAt: string;
  readonly id: string;
  readonly title: string;
  readonly viewerCanEdit: boolean;
  readonly " $fragmentType": "BlogArticleCardFragment";
};
export type BlogArticleCardFragment$key = {
  readonly " $data"?: BlogArticleCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAvatarFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blurHash",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_ARTICLE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_ARTICLE\")"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "199348960dd79431282608a1db875ffc";

export default node;
