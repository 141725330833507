/**
 * @generated SignedSource<<b0d7c5cd2567531215fdb0232e486d54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticlesPageQuery$variables = {
  slug: string;
};
export type BlogArticlesPageQuery$data = {
  readonly forumBySlug: {
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"BlogArticlesPageArticlesFragment" | "BlogArticlesPageLatestArticlesFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly canCreateArticle: boolean;
  };
};
export type BlogArticlesPageQuery = {
  response: BlogArticlesPageQuery$data;
  variables: BlogArticlesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": "canCreateArticle",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_ARTICLE"
    }
  ],
  "kind": "ScalarField",
  "name": "can",
  "storageKey": "can(action:\"CREATE_ARTICLE\")"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TopicEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isEntity"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "article",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "blurHash",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "viewerCanEdit",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_ARTICLE"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_ARTICLE\")"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogArticlesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BlogArticlesPageArticlesFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BlogArticlesPageLatestArticlesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlogArticlesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": "HOT"
              }
            ],
            "concreteType": "TopicConnection",
            "kind": "LinkedField",
            "name": "topics",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "topics(first:10,order:\"HOT\")"
          },
          {
            "alias": "latestTopics",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 3
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": "NEWEST"
              }
            ],
            "concreteType": "TopicConnection",
            "kind": "LinkedField",
            "name": "topics",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "topics(first:3,order:\"NEWEST\")"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4457a5fc4398a788e3960b94b8ada13f",
    "id": null,
    "metadata": {},
    "name": "BlogArticlesPageQuery",
    "operationKind": "query",
    "text": "query BlogArticlesPageQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    canCreateArticle: can(action: CREATE_ARTICLE)\n    id\n  }\n  forumBySlug(slug: $slug) {\n    title\n    ...BlogArticlesPageArticlesFragment\n    ...BlogArticlesPageLatestArticlesFragment\n    id\n  }\n}\n\nfragment BlogArticleCardFragment on Topic {\n  id\n  title\n  createdAt\n  author {\n    __typename\n    ...EntityAvatarFragment\n    id\n  }\n  article {\n    shortDescription\n    image\n    blurHash\n    id\n  }\n  viewerCanEdit: viewerCan(action: UPDATE_ARTICLE)\n}\n\nfragment BlogArticlesPageArticlesFragment on Forum {\n  topics(first: 10, order: HOT) {\n    edges {\n      node {\n        id\n        ...BlogArticleCardFragment\n      }\n    }\n  }\n}\n\nfragment BlogArticlesPageLatestArticlesFragment on Forum {\n  latestTopics: topics(first: 3, order: NEWEST) {\n    edges {\n      node {\n        id\n        ...BlogArticleCardFragment\n      }\n    }\n  }\n}\n\nfragment EntityAvatarFragment on Entity {\n  __isEntity: __typename\n  username\n  createdAt\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "20a2215a158f8b2b30f13ddf86e7b0da";

export default node;
