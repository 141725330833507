import {
  FormattedDate,
  FormattedMessage,
  defineMessage,
  useIntl,
} from "react-intl";
import { useBlogArticlePageQuery } from "./loaders/BlogArticlePage";
import ErrorPage from "./ErrorPage";
import Markdown from "../components/Markdown";
import TableOfContent from "../components/TableOfContent";
import Contributors from "../components/Contributors";
import { Link } from "react-router-dom";
import TopicCommentsForm from "../components/TopicCommentsForm";
import { Suspense } from "react";
import TopicComments from "../components/TopicComments";
import LazyImage from "../components/LazyImage";
import { extractMarkdownTitles } from "../utils/generateToc";
import TopicHelmet from "../components/TopicHelmet";

export default function BlogArticlePage() {
  const intl = useIntl();
  const {
    article: blog,
    query: { viewer },
  } = useBlogArticlePageQuery();

  if (!blog?.article && !blog) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found",
        })}
      />
    );
  }

  const titles = extractMarkdownTitles(blog.description ?? "");

  const imageAlt = defineMessage({
    defaultMessage: "Article image for {title}",
  });

  return (
    <>
      <TopicHelmet
        topic={blog}
        metaTitle={blog.article?.metadata?.title}
        metaDescription={
          blog.article?.metadata?.description ?? blog.article?.shortDescription
        }
        metaImageAlt={intl.formatMessage(imageAlt, {
          title: blog.article?.metadata?.title ?? blog.title,
        })}
        metaImageUrl={blog.article?.image}
      />
      <article className="flex flex-col items-start gap-2">
        <header className="flex flex-row justify-between w-full">
          <div className="flex flex-col gap-2">
            <p className="text-indigo text-sm font-semibold leading-tight">
              <FormattedMessage
                defaultMessage="{authorName} • {date}"
                values={{
                  authorName: blog.author.username,
                  date: (
                    <FormattedDate dateStyle="medium" value={blog.createdAt} />
                  ),
                }}
              />
            </p>
            <h1 className="text-gray-950 text-3xl font-semibold leading-10 -ml-1">
              {blog.title}
            </h1>
          </div>
          {blog.viewerCanEdit && (
            <Link to={"edit"}>
              <FormattedMessage defaultMessage="Edit" />
            </Link>
          )}
        </header>

        <figure className="w-full h-full rounded-xl overflow-hidden border border-gray-300/75 my-12">
          <LazyImage
            ratio={19 / 9}
            blurHash={blog.article?.blurHash}
            className="relative"
            image={{
              src: blog.article?.image,
              className: "h-full w-full object-cover object-center",
              alt: "Thumbnail",
            }}
          />
          <figcaption className="px-4 py-2 text-gray-400 text-xs">
            <FormattedMessage
              {...imageAlt}
              values={{ title: blog.article?.metadata?.title ?? blog.title }}
            />
          </figcaption>
        </figure>

        <div className="grid gap-8 lg:grid-cols-[auto,1fr] lg:gap-32">
          <aside className="hidden lg:flex flex-col sticky top-4 h-max gap-8">
            <TableOfContent titles={titles} />
            <hr className="text-gray-200" />
            {blog.article && <Contributors article={blog.article} />}
            <hr className="text-gray-200" />
          </aside>
          <section className="flex flex-col gap-8">
            <Markdown>{blog.description}</Markdown>
            <div className="lg:hidden order-1 mt-8 space-y-8">
              <hr className="text-gray-200" />
              {blog.article && <Contributors article={blog.article} />}
              <hr className="text-gray-200" />
            </div>
          </section>
        </div>

        <footer className="py-5 w-full mt-12">
          <TopicCommentsForm topic={blog} viewer={viewer} order="NEWEST" />
          <Suspense fallback={<TopicComments.Skeleton />}>
            <TopicComments topic={blog} order="NEWEST" />
          </Suspense>
        </footer>
      </article>
    </>
  );
}
