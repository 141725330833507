import { useEffect, useState } from "react";
import { TOCItem } from "../utils/generateToc";
import scrollIntoView from "scroll-into-view-if-needed";
import { cn } from "../utils/tailwind";
import { FormattedMessage } from "react-intl";

export interface Props {
  titles: TOCItem[];
  indentationFactor?: number;
}

const OBSERVER = {
  root: null,
  rootMargin: "0px 0px -70% 0px",
  threshold: 1.0,
};

export default function TableOfContent({
  titles,
  indentationFactor = 25,
}: Props) {
  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, OBSERVER);
    titles.forEach((heading) => {
      const element = document.getElementById(heading.id);
      if (element) observer.observe(element);
    });
    return () => observer.disconnect();
  }, [titles]);

  const handleScroll = (id: string) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      scrollIntoView(targetElement, {
        behavior: "smooth",
        scrollMode: "if-needed",
      });
    }
    setActiveId(id);
    window.history.pushState(null, "", `#${id}`);
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base font-semibold leading-normal text-gray-950">
        <FormattedMessage defaultMessage="Table of contents" />
      </p>
      <ul>
        {titles.map((heading) => (
          <li
            key={heading.id}
            style={{
              marginInlineStart: getIndentation(
                heading.depth,
                indentationFactor,
              ),
            }}
          >
            <button
              onClick={() => handleScroll(heading.id)}
              className={cn(
                activeId === heading.id ? "text-indigo" : "text-gray-600",
                "text-base font-normal leading-normal",
              )}
            >
              {heading.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

function getIndentation(depth: number, indentationFactor: number): number {
  return (depth - 1) * indentationFactor;
}
