import { FormattedMessage, useIntl } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { useBlogArticleCreatePageQuery } from "./loaders/BlogArticleCreatePage";
import ErrorPage from "./ErrorPage";
import ArticleEditForm, { SubmitData } from "../components/ArticleEditForm";
import { UploadableMap } from "relay-runtime";
import {
  BlogArticleCreatePageMutation as BlogArticleCreatePageMutationType,
  BlogArticleCreatePageMutation$variables,
} from "./__generated__/BlogArticleCreatePageMutation.graphql";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { logger } from "../common/logger";
import { formatSlug } from "../utils/format";

const BlogArticleCreatePageMutation = graphql`
  mutation BlogArticleCreatePageMutation(
    $forumId: ID!
    $input: CreateArticleInput!
  ) {
    createArticle(forumId: $forumId, input: $input) {
      node {
        id
        title
      }
    }
  }
`;

export default function BlogArticleCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useBlogArticleCreatePageQuery();
  const forum = query?.forumBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<BlogArticleCreatePageMutationType>(
      BlogArticleCreatePageMutation,
    );

  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found",
        })}
      />
    );
  }

  const handleOnSubmit = ({ data, image }: SubmitData) => {
    const variables: BlogArticleCreatePageMutation$variables = {
      forumId: forum.id,
      input: {
        title: data.title,
        content: data.content,
        shortDescription: data.shortDescription,
        authorsIds: data.authorsIds,
        metadata: data.metadata,
      },
    };
    variables.input.image = image.variable;
    const uploadables: UploadableMap = {};
    if (image.uploadable) {
      uploadables["variables.input.image"] = image.uploadable;
    }
    commitMutation({
      variables,
      uploadables,
      onError: (error) => {
        logger.error(error);
        toast.error(
          intl.formatMessage({ defaultMessage: "Fail to create the article." }),
        );
      },
      onCompleted: ({ createArticle: { node } }, _) => {
        navigate(`/blog/${node.id}/${formatSlug(node.title)}`);
      },
    });
  };

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Create new blog article"} />
      </h1>
      <ArticleEditForm
        onSubmit={handleOnSubmit}
        isDisabled={isMutationInFlight}
        userAutoComplete={query}
      />
    </div>
  );
}
