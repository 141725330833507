import { useEffect, useRef, useState } from "react";
import { graphql, useFragment } from "react-relay";
import {
  HeaderFragment$data,
  HeaderFragment$key,
} from "./__generated__/HeaderFragment.graphql";
import { FormattedMessage } from "react-intl";
import NavItem from "./NavItem";
import { Dropdown } from "./Dropdown";
import LogoutButton from "./LogoutButton";
import EntityProfilePic from "./EntityProfilePic";
import Button from "./Button";
import Logo from "./Logo";
import * as Drawer from "./Drawer";
import {
  MdMenu,
  MdChevronRight,
  MdOutlineNotificationsActive,
  MdOutlinePersonOutline,
} from "react-icons/md";
import { useLocation, Link, LinkProps } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";
import { cn } from "../utils/tailwind";

const HeaderFragment = graphql`
  fragment HeaderFragment on Query {
    viewer @ifAllowed {
      id
      displayName
      username
      canCreateEvent: can(action: CREATE_EVENT)
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    events(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

interface Props {
  query: HeaderFragment$key;
}

const NavItems = ({
  showEvents,
  vertical = false,
}: {
  showEvents: boolean;
  vertical?: boolean;
}) => (
  <>
    <NavItem to={`/competitions`} vertical={vertical}>
      <FormattedMessage defaultMessage="Competitions" />
    </NavItem>
    {showEvents && (
      <NavItem to={`/events`} vertical={vertical}>
        <FormattedMessage defaultMessage="Events" />
      </NavItem>
    )}
    <NavItem to={`/discussions`} vertical={vertical}>
      <FormattedMessage defaultMessage="Discussions" />
    </NavItem>
    <NavItem to={`/leaderboard`} vertical={vertical}>
      <FormattedMessage defaultMessage="Leaderboard" />
    </NavItem>
    <NavItem to={`/blog`} vertical={vertical}>
      <FormattedMessage defaultMessage="Blog" />
    </NavItem>
    {import.meta.env.VITE_QUANTUM_JOBS && (
      <NavItem
        to={import.meta.env.VITE_QUANTUM_JOBS}
        target="_blank"
        vertical={vertical}
        icon={<MdOutlineArrowOutward />}
      >
        <FormattedMessage defaultMessage="Job Board" />
      </NavItem>
    )}
    {import.meta.env.VITE_Q3AS && (
      <NavItem
        to={import.meta.env.VITE_Q3AS}
        target="_blank"
        vertical={vertical}
        icon={<MdOutlineArrowOutward />}
      >
        <FormattedMessage defaultMessage="Q3AS" />
      </NavItem>
    )}
  </>
);

const UserMenuButton = ({ className, children, ...rest }: LinkProps) => {
  return (
    <Link
      className={cn(
        "flex flex-row items-center space-x-4 p-2 hover:bg-gray-100 rounded-lg",
        className,
      )}
      {...rest}
    >
      <div className="flex flex-row items-center space-x-4 w-full">
        {children}
      </div>
      <div>
        <MdChevronRight />
      </div>
    </Link>
  );
};

const UserMenu = ({
  viewer,
  onClick,
}: {
  viewer: HeaderFragment$data["viewer"];
  onClick?: () => void;
}) => {
  if (!viewer) {
    return null;
  }
  return (
    <div className="p-4 flex flex-col space-y-2">
      <UserMenuButton to={`/${viewer.username}`} onClick={onClick}>
        <EntityProfilePic entity={viewer} size="10" />
        <div>
          <div className={cn("font-semibold")}>{viewer.displayName}</div>
          <div>@{viewer.username}</div>
        </div>
      </UserMenuButton>
      <UserMenuButton to={`/${viewer.username}/edit`} onClick={onClick}>
        <MdOutlinePersonOutline className="text-2xl" />
        <div>
          <FormattedMessage defaultMessage="Profile Settings" />
        </div>
      </UserMenuButton>
      <UserMenuButton to="settings/notifications" onClick={onClick}>
        <MdOutlineNotificationsActive className="text-2xl" />
        <div>
          <FormattedMessage defaultMessage="Notification Settings" />
        </div>
      </UserMenuButton>
      <div className="pt-2">
        <LogoutButton />
      </div>
    </div>
  );
};

const UserButtonsNavBar = ({
  viewer,
}: {
  viewer: HeaderFragment$data["viewer"];
}) => {
  const [open, setOpen] = useState(false);
  if (viewer) {
    return (
      <Dropdown
        open={open}
        onOpenChange={setOpen}
        trigger={
          <div
            className="flex flex-row items-center hover:cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <EntityProfilePic entity={viewer} size="10" />
          </div>
        }
      >
        <Dropdown.List>
          <UserMenu viewer={viewer} onClick={() => setOpen(false)} />
        </Dropdown.List>
      </Dropdown>
    );
  }
  return (
    <>
      <Button kind="text" to="/login">
        <FormattedMessage defaultMessage="Login" />
      </Button>
      <Button to="/signup">
        <FormattedMessage defaultMessage="Signup" />
      </Button>
    </>
  );
};

const UserButtonsDrawer = ({
  viewer,
}: {
  viewer: HeaderFragment$data["viewer"];
}) => {
  if (viewer) {
    return <UserMenu viewer={viewer} />;
  }
  return (
    <div className="flex flex-col space-y-2">
      <Button to="/signup" className="w-full">
        <FormattedMessage defaultMessage="Signup" />
      </Button>
      <Button kind="text" to="/login" className="w-full">
        <FormattedMessage defaultMessage="Login" />
      </Button>
    </div>
  );
};

export default function Header({ query }: Props) {
  const data = useFragment(HeaderFragment, query);
  const location = useLocation();
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (closeRef.current) {
      closeRef.current.click();
    }
  }, [location]);

  const showEvents =
    data.events.edges.length > 0 || data.viewer?.canCreateEvent;

  return (
    <div className="border-b pb-2 mb-6 bg-white">
      <div className="container mx-auto max-w-screen-lg flex flex-row pt-3">
        <Link to="/" className="flex">
          <Button kind="text" className="flex items-center mr-2">
            <div className="w-28">
              <Logo />
            </div>
          </Button>
        </Link>

        <div className="hidden lg:flex flex-row space-x-1 items-center w-full">
          <NavItems showEvents={showEvents} />
        </div>

        <div className="hidden lg:flex flex-grow-0 items-center pr-2">
          <UserButtonsNavBar viewer={data.viewer} />
        </div>

        <div className="lg:hidden w-full" />

        <div className="lg:hidden">
          <Drawer.Root>
            <Drawer.Trigger asChild>
              <div className="inline-block px-4 py-2 hover:cursor-pointer">
                <MdMenu size={24} />
              </div>
            </Drawer.Trigger>
            <Drawer.Content>
              <div className="flex flex-col py-4 space-y-2">
                <NavItem to={`/`} vertical>
                  <FormattedMessage defaultMessage="Home" />
                </NavItem>
                <NavItems showEvents={showEvents} vertical />
                <div className="border-t px-2">
                  <UserButtonsDrawer viewer={data.viewer} />
                </div>
              </div>
            </Drawer.Content>
            <Drawer.Close asChild>
              <button ref={closeRef} className="hidden" />
            </Drawer.Close>
          </Drawer.Root>
        </div>
      </div>
    </div>
  );
}
