import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash";
import AspectDiv, { type AspectProps } from "./AspectDiv";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { Skeleton } from "./Skeleton";

export interface Props extends AspectProps {
  blurHash?: string | null;
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export default function LazyImage({
  blurHash,
  image,
  ...rest
}: Readonly<Props>) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { offsetWidth: width, offsetHeight: height } = containerRef.current;
      setDimensions({ width, height });
    }
  }, []);

  useEffect(() => {
    if (image?.src) {
      const img = new Image();
      img.onload = () => {
        setIsLoaded(true);
      };

      img.onerror = () => setIsLoaded(false);
      img.src = image.src;
    } else {
      setIsLoaded(false);
    }
  }, [image?.src]);

  const { width, height } = dimensions;

  return (
    <AspectDiv
      ref={containerRef}
      style={{
        backgroundImage:
          !isLoaded && !image?.src ? `url(${defaultThumbnail})` : undefined,
        backgroundSize: "cover",
      }}
      {...rest}
    >
      {!isLoaded && typeof blurHash === "string" && (
        <Blurhash
          hash={blurHash}
          width={width}
          height={height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      {!isLoaded && typeof blurHash !== "string" && (
        <Skeleton className="w-full h-full" />
      )}
      {image?.src && (
        <img
          {...image}
          style={{
            display: "block",
          }}
        />
      )}
    </AspectDiv>
  );
}
