import { graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import { useBlogArticleEditPageQuery } from "./loaders/BlogArticleEditPage";
import ArticleEditForm from "../components/ArticleEditForm";
import { UploadableMap } from "relay-runtime";
import { logger } from "../common/logger";
import { useNavigate } from "react-router-dom";
import {
  BlogArticleEditPageMutation$variables,
  BlogArticleEditPageMutation as BlogArticleEditPageMutationType,
} from "./__generated__/BlogArticleEditPageMutation.graphql";
import TopicDeleteButton from "../components/TopicDeleteButton";
import { formatSlug } from "../utils/format";

const BlogArticleEditPageMutation = graphql`
  mutation BlogArticleEditPageMutation($id: ID!, $input: UpdateArticleInput!) {
    updateArticle(id: $id, input: $input) {
      node {
        id
        title
        content: description
        article {
          image
          metadata {
            title
            description
          }
          ...ContributorsFragment
        }
      }
    }
  }
`;

export default function BlogArticleEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useBlogArticleEditPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<BlogArticleEditPageMutationType>(BlogArticleEditPageMutation);
  const article = query.node;
  if (!article.article && !article) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found /:",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Edit Article"} />
      </h1>
      <ArticleEditForm
        userAutoComplete={query}
        isDisabled={isMutationInFlight}
        defaultValues={{
          ...article.article,
          title: article.title,
          content: article.content,
          authorsIds: article.article?.authors.edges.map(
            (author) => author.node.id,
          ),
        }}
        onSubmit={({ data, dirtyFields, image, setFormError }) => {
          const variables: BlogArticleEditPageMutation$variables = {
            id: article.article!.id,
            input: {
              title: dirtyFields.title ? data.title : undefined,
              content: dirtyFields.content ? data.content : undefined,
              shortDescription: dirtyFields.shortDescription
                ? data.shortDescription
                : undefined,
              authorsIds: dirtyFields.authorsIds ? data.authorsIds : undefined,
              metadata: dirtyFields.metadata
                ? {
                    title: dirtyFields.metadata?.title
                      ? data.metadata.title
                      : undefined,
                    description: dirtyFields.metadata?.description
                      ? data.metadata.description
                      : undefined,
                  }
                : undefined,
              image: image.variable,
            },
          };
          const uploadables: UploadableMap = {};
          if (image.uploadable) {
            uploadables["variables.input.image"] = image.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError(error) {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage: "Could not update article.",
                }),
              );
            },

            onCompleted: ({ updateArticle: { node } }, _) => {
              navigate(`/blog/${node.id}/${formatSlug(node.title)}`);
            },
          });
        }}
      />
      <TopicDeleteButton topic={article} />
    </div>
  );
}
