import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";

export const BlogArticleEditPageQuery = graphql`
  query BlogArticleEditPageQuery($id: ID!) {
    ...UserAutocompleteFragment
    node(id: $id) {
      ... on Topic {
        id
        url
        title
        content: description
        ...TopicDeleteButtonFragment
        article {
          id
          image
          shortDescription
          metadata {
            title
            description
          }
          authors {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const blogArticleEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<BlogArticleEditPageQueryType>(
      environment,
      BlogArticleEditPageQuery,
      {
        id,
      },
    );
  };
};

export const useBlogArticleEditPageQuery = () =>
  usePreloaded<BlogArticleEditPageQueryType>();
