/**
 * @generated SignedSource<<27304930a1d1b1d03fbdeb37f5db92ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleCreatePageQuery$variables = {
  slug: string;
};
export type BlogArticleCreatePageQuery$data = {
  readonly forumBySlug: {
    readonly id: string;
    readonly slug: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserAutocompleteFragment">;
};
export type BlogArticleCreatePageQuery = {
  response: BlogArticleCreatePageQuery$data;
  variables: BlogArticleCreatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "slug"
    }
  ],
  "concreteType": "Forum",
  "kind": "LinkedField",
  "name": "forumBySlug",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogArticleCreatePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserAutocompleteFragment"
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlogArticleCreatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 10
          }
        ],
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10)"
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3e1a3ad66f102b95cc3781d6d81032e2",
    "id": null,
    "metadata": {},
    "name": "BlogArticleCreatePageQuery",
    "operationKind": "query",
    "text": "query BlogArticleCreatePageQuery(\n  $slug: String!\n) {\n  ...UserAutocompleteFragment\n  forumBySlug(slug: $slug) {\n    id\n    slug\n  }\n}\n\nfragment UserAutocompleteFragment on Query {\n  entities(first: 10) {\n    edges {\n      node {\n        __typename\n        id\n        kind\n        username\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11355558e88418d80e8fce3d891bf540";

export default node;
