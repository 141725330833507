import { graphql, Environment, useFragment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticlePageFragment$key } from "./__generated__/BlogArticlePageFragment.graphql";
import { BlogArticlePageQuery as BlogArticlePageQueryType } from "./__generated__/BlogArticlePageQuery.graphql";

const Fragment = graphql`
  fragment BlogArticlePageFragment on Topic {
    id
    title
    createdAt
    article {
      shortDescription
      image
      blurHash
      metadata {
        title
        description
      }
      ...ContributorsFragment
    }
    author {
      username
    }
    description
    viewerCanEdit: viewerCan(action: UPDATE_ARTICLE)
    ...TopicCommentsFormTopicFragment
    ...TopicCommentsFragment @arguments(order: NEWEST)
    ...VoteDisplayFragment
    ...TopicHelmetFragment
  }
`;

export const ArticlePageQuery = graphql`
  query BlogArticlePageQuery($id: ID!) {
    viewer @ifAllowed {
      ...TopicCommentsFormViewerFragment
    }
    node(id: $id) {
      __typename
      ... on Topic {
        ...BlogArticlePageFragment
      }
    }
  }
`;

export const blogArticlePageQueryLoader = (environment: Environment) => {
  return ({ params: { id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<BlogArticlePageQueryType>(environment, ArticlePageQuery, {
      id,
    });
  };
};

export const useBlogArticlePageQuery = () => {
  const { query, ...preloaded } = usePreloaded<BlogArticlePageQueryType>();
  const article = useFragment<BlogArticlePageFragment$key>(
    Fragment,
    query.node.__typename === "Topic" ? query.node : null,
  );
  return { ...preloaded, article, query };
};
