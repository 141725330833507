import { graphql, useFragment } from "react-relay";
import { ActivityCardHostFragment$key } from "./__generated__/ActivityCardHostFragment.graphql";
import Card from "./Card";

import EntityProfilePic from "./EntityProfilePic";

import { useNavigate } from "react-router-dom";
import { ActivityCardTagsFragment$key } from "./__generated__/ActivityCardTagsFragment.graphql";
import Tag from "./CompetitionTag";

import LazyImage from "./LazyImage";

const ActivityCardHostFragment = graphql`
  fragment ActivityCardHostFragment on Entity {
    username
    ...EntityProfilePicFragment @arguments(thumbnail: true)
  }
`;

export const ActivityCardTagsFragment = graphql`
  fragment ActivityCardTagsFragment on CompetitionTagConnection {
    edges {
      node {
        id
        ...CompetitionTagFragment
      }
    }
  }
`;

interface Props {
  title: string;
  shortDescription: string;
  thumbnail?: string;
  url: string;
  host: ActivityCardHostFragment$key;
  tags?: ActivityCardTagsFragment$key;
}

export default function ActivityCard({
  title,
  shortDescription,
  thumbnail,
  url,
  host: hostFragment,
  tags: tagsFragment,
}: Props) {
  const host = useFragment(ActivityCardHostFragment, hostFragment);
  const tags = useFragment(ActivityCardTagsFragment, tagsFragment);
  const navigate = useNavigate();

  return (
    <Card
      className="h-full flex flex-col"
      onClick={(event) => {
        navigate(url);
        event.stopPropagation();
      }}
    >
      <LazyImage
        ratio={16 / 9}
        className="rounded-xl inline-flex overflow-hidden relative border border-gray-300/75"
        image={{
          src: thumbnail,
          className: "h-full w-full object-cover object-center rounded-lg",
          alt: "Thumbnail",
        }}
      />
      {tags && (
        <div className="gap-2 flex flex-row flex-wrap">
          {tags.edges.map((tag) => (
            <Tag tag={tag.node} key={tag.node.id} />
          ))}
        </div>
      )}
      <div className="flex flex-row flex-1">
        <div className="flex-grow flex flex-col">
          <div className="flex-1">
            <h1 className="text-xl font-bold font-poppins">{title}</h1>
          </div>
          <div className="flex-1">
            <h2 className="text-l">{shortDescription}</h2>
          </div>
        </div>
        <div
          className="flex-grow-0"
          onClick={(event) => {
            navigate(`/${host.username}`);
            event.stopPropagation();
          }}
        >
          <div className="w-12">
            <EntityProfilePic entity={host} />
          </div>
        </div>
      </div>
    </Card>
  );
}
