import { graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { cn } from "../utils/tailwind";
import EntityAvatar from "./EntityAvatar";
import LazyImage from "./LazyImage";
import Card from "./Card";
import { BlogArticleCardFragment$key } from "./__generated__/BlogArticleCardFragment.graphql";
import { formatSlug } from "../utils/format";

const BlogArticleCardFragment = graphql`
  fragment BlogArticleCardFragment on Topic {
    id
    title
    createdAt
    author {
      ...EntityAvatarFragment
    }
    article {
      shortDescription
      image
      blurHash
    }
    viewerCanEdit: viewerCan(action: UPDATE_ARTICLE)
  }
`;

export type BlogArticleCardLayout = "column" | "row";

interface Props {
  article: BlogArticleCardFragment$key;
  layout?: BlogArticleCardLayout;
}

export function BlogArticleCard({
  article: articleFragment,
  layout = "column",
}: Props) {
  const navigate = useNavigate();
  const blogArticle = useFragment(BlogArticleCardFragment, articleFragment);
  const { article, id, title, createdAt: date } = blogArticle;
  const isRowLayout = layout === "row";

  return (
    <Card
      className={cn("flex items-start gap-3", {
        "flex-row": isRowLayout,
        "flex-col": !isRowLayout,
      })}
      onClick={(event) => {
        navigate(`/blog/${id}/${formatSlug(title)}`);
        event.stopPropagation();
      }}
    >
      <LazyImage
        blurHash={article?.blurHash}
        ratio={isRowLayout ? 5 / 2 : 16 / 9}
        className="rounded-xl inline-flex overflow-hidden relative border border-gray-300/75"
        image={{
          src: article?.image,
          className: "h-full w-full object-cover object-center rounded-lg",
          alt: "Thumbnail",
        }}
      />
      <div
        className={cn("flex gap-3", {
          "flex-row justify-between items-center w-2/4": isRowLayout,
          "flex-col": !isRowLayout,
        })}
      >
        <div className="flex flex-col gap-3  justify-between">
          <p className="text-lg font-semibold text-gray-900 leading-7 line-clamp-2">
            {title}
          </p>
          <p className="text-base font-normal text-gray-600 leading-normal line-clamp-2">
            {article?.shortDescription}
          </p>
          <EntityAvatar entity={blogArticle.author} date={new Date(date)} />
        </div>
      </div>
    </Card>
  );
}
