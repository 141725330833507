/**
 * @generated SignedSource<<77d457396f77a461039721a2b377b658>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateArticleInput = {
  authorsIds?: ReadonlyArray<string> | null | undefined;
  content?: string | null | undefined;
  image?: any | null | undefined;
  metadata?: ArticleMetadataInput | null | undefined;
  shortDescription?: string | null | undefined;
  title?: string | null | undefined;
};
export type ArticleMetadataInput = {
  description?: string | null | undefined;
  title?: string | null | undefined;
};
export type BlogArticleEditPageMutation$variables = {
  id: string;
  input: UpdateArticleInput;
};
export type BlogArticleEditPageMutation$data = {
  readonly updateArticle: {
    readonly node: {
      readonly article: {
        readonly image: any | null | undefined;
        readonly metadata: {
          readonly description: string | null | undefined;
          readonly title: string | null | undefined;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ContributorsFragment">;
      } | null | undefined;
      readonly content: string | null | undefined;
      readonly id: string;
      readonly title: string;
    };
  };
};
export type BlogArticleEditPageMutation = {
  response: BlogArticleEditPageMutation$data;
  variables: BlogArticleEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": "content",
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogArticleEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TopicEdge",
        "kind": "LinkedField",
        "name": "updateArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "article",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SeoMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContributorsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlogArticleEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TopicEdge",
        "kind": "LinkedField",
        "name": "updateArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "article",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SeoMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArticleAuthorsConnection",
                    "kind": "LinkedField",
                    "name": "authors",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EntityEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEntity"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageThumbnail",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60d1f0cbf4bccdb571174d16c7bcc9f3",
    "id": null,
    "metadata": {},
    "name": "BlogArticleEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation BlogArticleEditPageMutation(\n  $id: ID!\n  $input: UpdateArticleInput!\n) {\n  updateArticle(id: $id, input: $input) {\n    node {\n      id\n      title\n      content: description\n      article {\n        image\n        metadata {\n          title\n          description\n          id\n        }\n        ...ContributorsFragment\n        id\n      }\n    }\n  }\n}\n\nfragment ContributorsFragment on Article {\n  authors {\n    edges {\n      node {\n        __typename\n        ...EntityAvatarFragment\n        id\n      }\n    }\n  }\n}\n\nfragment EntityAvatarFragment on Entity {\n  __isEntity: __typename\n  username\n  createdAt\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "8085b88eb3c8dd79d7032e00074ba20c";

export default node;
