import { cn } from "../utils/tailwind";

export default function Card({
  className,
  onClick,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "rounded-xl",
        onClick ? "hover:cursor-pointer" : "",
        className,
      )}
      onClick={onClick}
      {...props}
    />
  );
}
