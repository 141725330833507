import { graphql, Environment } from "react-relay";
import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticleCreatePageQuery as BlogArticleCreatePageQueryType } from "./__generated__/BlogArticleCreatePageQuery.graphql";

export const BLOG_SLUG = "blog";

export const BlogArticleCreatePageQuery = graphql`
  query BlogArticleCreatePageQuery($slug: String!) {
    ...UserAutocompleteFragment
    forumBySlug(slug: $slug) {
      id
      slug
    }
  }
`;

export const blogArticleCeatePageQueryLoader = (environment: Environment) => {
  return () => {
    return preload<BlogArticleCreatePageQueryType>(
      environment,
      BlogArticleCreatePageQuery,
      { slug: BLOG_SLUG },
    );
  };
};

export const useBlogArticleCreatePageQuery = () =>
  usePreloaded<BlogArticleCreatePageQueryType>();
