import { graphql, useFragment } from "react-relay";
import { ContributorsFragment$key } from "./__generated__/ContributorsFragment.graphql";
import EntityAvatar from "./EntityAvatar";

const ContributorsFragment = graphql`
  fragment ContributorsFragment on Article {
    authors {
      edges {
        node {
          ...EntityAvatarFragment
        }
      }
    }
  }
`;

export interface Props {
  article: ContributorsFragment$key;
}

export default function Contributors({ article: articleFragment }: Props) {
  const { authors } = useFragment(ContributorsFragment, articleFragment);
  return (
    <>
      {authors.edges.map((author, index) => (
        <EntityAvatar key={index} entity={author.node} />
      ))}
    </>
  );
}
