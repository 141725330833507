import { graphql, Environment } from "react-relay";

import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticlesPageQuery as BlogArticlesPageQueryType } from "./__generated__/BlogArticlesPageQuery.graphql";
import { BLOG_SLUG } from "./BlogArticleCreatePage";

export const BlogArticlesPageQuery = graphql`
  query BlogArticlesPageQuery($slug: String!) {
    viewer @ifAllowed {
      canCreateArticle: can(action: CREATE_ARTICLE)
    }
    forumBySlug(slug: $slug) {
      title
      ...BlogArticlesPageArticlesFragment
      ...BlogArticlesPageLatestArticlesFragment
    }
  }
`;

export const blogArticlesPageQueryLoader = (environment: Environment) => {
  return () => {
    return preload<BlogArticlesPageQueryType>(
      environment,
      BlogArticlesPageQuery,
      {
        slug: BLOG_SLUG,
      },
    );
  };
};

export const useBlogArticlesPageQuery = () =>
  usePreloaded<BlogArticlesPageQueryType>();
