export const BANNED_USERNAMES = [
  "admin",
  "administrator",
  "api",
  "aqora",
  "auth",
  "blog",
  "callback",
  "cdn",
  "competition",
  "competitions",
  "contact",
  "data",
  "docs",
  "email",
  "enable",
  "event",
  "events",
  "fraud",
  "graphql",
  "group",
  "guest",
  "help",
  "hostmaster",
  "lobster",
  "lobsters",
  "login",
  "logout",
  "mailer-daemon",
  "moderator",
  "moderators",
  "new",
  "news",
  "nobody",
  "oauth",
  "postmaster",
  "root",
  "security",
  "signup",
  "support",
  "sysop",
  "test",
  "user",
  "viewer",
  "webmaster",
  "forums",
  "settings",
  "blog",
];
export const BANNED_COMPETITION_SLUGS = ["new", "edit"];
