/**
 * @generated SignedSource<<c98ab36079ab00af26cfa7ecd4086a98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityAvatarFragment$data = {
  readonly createdAt: string;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  readonly " $fragmentType": "EntityAvatarFragment";
};
export type EntityAvatarFragment$key = {
  readonly " $data"?: EntityAvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};

(node as any).hash = "d5b97971742e3d5e4139d9d32b99d907";

export default node;
