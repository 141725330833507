import React from "react";
import BaseMarkdown, { Options } from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.css";
import CodeViewer from "./CodeViewer";
import { Mention } from "./Mention";
import { cn } from "../utils/tailwind";
import { formatSlug } from "../utils/format";

const MENTION_PATTERN = /(@\w+)/g;

function parseMentions(children: React.ReactNode): React.ReactNode {
  if (typeof children === "string") {
    return children
      .split(MENTION_PATTERN)
      .map((part, index) =>
        part.startsWith("@") ? (
          <Mention username={part.substring(1)} key={index} />
        ) : (
          <React.Fragment key={index}>{part}</React.Fragment>
        ),
      );
  } else if (Array.isArray(children)) {
    return children.map((child, index) => (
      <React.Fragment key={index}>{parseMentions(child)}</React.Fragment>
    ));
  }

  return children;
}

export type { Options as MarkdownProps };

export default function Markdown({ components, ...rest }: Options) {
  return (
    <BaseMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      className={"space-y-2"}
      components={{
        h1({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pb-4 pt-6">
              <h2
                id={id}
                className={`${className || ""} text-3xl font-bold`}
                {...props}
              />
            </div>
          );
        },
        h2({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pb-3 pt-5">
              <h2
                id={id}
                className={`${className || ""} text-2xl font-bold`}
                {...props}
              />
            </div>
          );
        },
        h3({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pt-4">
              <h3
                id={id}
                className={`${className || ""} text-xl font-bold`}
                {...props}
              />
            </div>
          );
        },
        h4({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pt-3">
              <h4
                id={id}
                className={`${className || ""} text-l font-bold`}
                {...props}
              />
            </div>
          );
        },
        h5({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pt-2">
              <h4
                id={id}
                className={`${className || ""} font-bold`}
                {...props}
              />
            </div>
          );
        },
        h6({ className, ...props }) {
          const id = formatSlug(String(props.children));
          return (
            <div className="pt-2">
              <h4 id={id} className={`${className || ""}`} {...props} />
            </div>
          );
        },
        p({ className, ...props }) {
          return (
            <div className={`${className || ""}`} {...props}>
              {parseMentions(props.children)}
            </div>
          );
        },
        a({ className, ...props }) {
          return (
            <a className={`${className || ""} text-blue-500`} {...props} />
          );
        },
        ul({ className, ...props }) {
          const classNameStr = className || "";
          const isTaskList = classNameStr.includes("contains-task-list");
          if (isTaskList) {
            return <ul className={`${classNameStr}`} {...props} />;
          } else {
            return (
              <ul className={`${classNameStr} ml-5 list-disc`} {...props} />
            );
          }
        },
        ol({ className, ...props }) {
          const classNameStr = className || "";
          return (
            <ol className={`${classNameStr} ml-5 list-decimal`} {...props} />
          );
        },
        table({ className, ...props }) {
          return (
            <div className="overflow-x-auto py-2">
              <table
                className={`${
                  className || ""
                } table-auto border border-collapse`}
                {...props}
              />
            </div>
          );
        },
        td({ className, ...props }) {
          return (
            <td className={`${className || ""} px-3 py-2 border`} {...props} />
          );
        },
        th({ className, ...props }) {
          return (
            <th
              className={`${className || ""} px-3 py-2 border bg-gray-100`}
              {...props}
            />
          );
        },
        pre({ className, children }) {
          return (
            <>
              {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child as React.ReactElement, {
                    className: `code-is-block ${child.props.className || ""} ${
                      className || ""
                    }`,
                  });
                }
                return child;
              })}
            </>
          );
        },
        code({ children, className, node: _node, ref, ...props }) {
          const classNameStr = className || "";
          if (classNameStr.includes("code-is-block")) {
            const match = /language-(\w+)/.exec(classNameStr);
            const language = match ? match[1] : "text";
            return (
              <div
                className="py-2"
                ref={ref as unknown as React.LegacyRef<HTMLDivElement>}
              >
                <CodeViewer language={language} noNumbers>
                  {String(children)}
                </CodeViewer>
              </div>
            );
          } else {
            return (
              <code
                {...props}
                className={`${className || ""} rounded-md bg-gray-100 p-1`}
              >
                {children}
              </code>
            );
          }
        },
        blockquote({ children, className, ...props }) {
          return (
            <blockquote
              className={cn("mt-4 border-l-2 pl-4 italic", className)}
              {...props}
            >
              {children}
            </blockquote>
          );
        },
        ...components,
      }}
      {...rest}
    />
  );
}
