import { FormattedMessage, useIntl } from "react-intl";
import FormGroup from "./FormGroup";
import { graphql, useLazyLoadQuery } from "react-relay";
import AspectDiv from "./AspectDiv";
import { useForm } from "react-hook-form";
import { Suspense, useState } from "react";
import { useImagePreview, useUpload } from "../utils/hooks";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { maxFileSize } from "../utils/validation";
import Button from "./Button";
import TextInput from "./TextInput";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";
import MarkdownEditor from "./MarkdownEditor";
import {
  MarkdownEditorContextProps,
  MarkdownValue,
} from "./MarkdownEditor/context";
import { UserAutocompleteFragment$key } from "./__generated__/UserAutocompleteFragment.graphql";
import UserAutocomplete from "./UserAutocomplete";
import EntityAvatar from "./EntityAvatar";
import { ArticleEditFormAuthorQuery as ArticleEditFormAuthorQueryType } from "./__generated__/ArticleEditFormAuthorQuery.graphql";
import { MdDelete } from "react-icons/md";
import { DirtyFields } from "../utils/types";

const ArticleEditFormAuthorQuery = graphql`
  query ArticleEditFormAuthorQuery($id: ID!) {
    node(id: $id) {
      ... on Entity {
        id
        ...EntityAvatarFragment
      }
    }
  }
`;

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface Metadata {
  title: string;
  description: string;
}

export interface FormData {
  title: string;
  content: string;
  shortDescription: string;
  image: FileList;
  authorsIds: ID[];
  metadata: Metadata;
}

interface DefaultValues {
  title?: string;
  content?: string | null;
  shortDescription?: string;
  image?: string | null;
  authorsIds?: ID[] | null;
  metadata?: Partial<Nullable<Metadata>> | null;
}

export interface SubmitData {
  data: FormData;
  image: { variable: null | undefined; uploadable: File | undefined };
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
  dirtyFields: Partial<DirtyFields<FormData>>;
}

interface Props {
  onSubmit: (data: SubmitData) => void;
  isDisabled?: boolean;
  defaultValues?: DefaultValues;
  userAutoComplete: UserAutocompleteFragment$key;
}

export default function ArticleEditForm({
  onSubmit,
  isDisabled,
  defaultValues,
  userAutoComplete,
}: Props) {
  const intl = useIntl();
  const {
    register,
    resetField,
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      authorsIds: defaultValues?.authorsIds ?? [],
    },
  });
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>(
    defaultValues?.content ?? "",
  );
  const image = useUpload(defaultValues?.image, watch("image"), () =>
    resetField("image"),
  );
  const selectedAuthorsIds = watch("authorsIds") || [];
  const imagePreview = useImagePreview(image.value);
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const errorMessages = {
    title: {
      required: intl.formatMessage({
        defaultMessage: "Title is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Title cannot be longer than 255 characters",
      }),
    },
    shortDescription: {
      required: intl.formatMessage({
        defaultMessage: "Short description is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage:
          "short description cannot be longer than 255 characters",
      }),
    },
    metaTitle: {
      maxLength: intl.formatMessage({
        defaultMessage: "Meta title cannot be longer than 255 characters",
      }),
    },
    metaDescription: {
      maxLength: intl.formatMessage({
        defaultMessage: "Meta description cannot be longer than 255 characters",
      }),
    },
    image: {
      size: intl.formatMessage({
        defaultMessage: "Image size must be at 5 MB",
      }),
    },
    content: {
      required: intl.formatMessage({
        defaultMessage: "Content is required",
      }),
    },
  };

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "content",
    control,
    defaultValue: defaultValues?.content ?? "",
    setMarkdownValue,
    markdownValue,
    canUploadFiles: true,
  };

  const doSubmit = handleSubmit((data) => {
    const isAuthorsIdsDirty =
      Array.isArray(defaultValues?.authorsIds) &&
      JSON.stringify(data.authorsIds) !==
        JSON.stringify(defaultValues?.authorsIds);
    onSubmit({
      data,
      dirtyFields: {
        authorsIds: isAuthorsIdsDirty,
        ...dirtyFields,
      },
      image: { variable: image.variable, uploadable: image.value },
      setFormError,
    });
  });

  const onAddAuthor = (authorId: ID) => {
    if (authorId == "") {
      return;
    }
    const currentAuthorIds = getValues("authorsIds") ?? [];
    if (!currentAuthorIds.includes(authorId)) {
      const updatedAuthorIds = [...currentAuthorIds, authorId];
      setValue("authorsIds", updatedAuthorIds);
    } else {
      setFormError(
        intl.formatMessage({
          defaultMessage: "Author is already selected",
        }),
      );
    }
  };

  const onRemoveAuthor = (authorId: ID) => {
    setValue(
      "authorsIds",
      getValues("authorsIds")?.filter((id) => id !== authorId),
    );
  };

  return (
    <form onSubmit={doSubmit} className="flex flex-col gap-10">
      {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Article banner" })}
        error={
          typeof errors.image?.type === "string" &&
          errorMessages.image[
            errors.image.type as keyof typeof errorMessages.image
          ]
        }
      >
        <div className="pb-4">
          <AspectDiv
            className="rounded-lg bg-grey"
            style={{
              backgroundImage: `url(${defaultThumbnail})`,
              backgroundSize: "1000px",
            }}
            ratio={4.75}
          >
            {image.isDirty
              ? imagePreview && (
                  <img
                    src={imagePreview}
                    className="h-full w-full rounded-lg object-cover object-center"
                  />
                )
              : defaultValues?.image && (
                  <img
                    src={defaultValues.image}
                    className="h-full w-full rounded-lg object-cover object-center"
                  />
                )}
          </AspectDiv>
        </div>
        <div>
          <input
            type="file"
            accept="image/png, image/jpeg"
            aria-invalid={errors.image ? "true" : "false"}
            {...register("image", {
              validate: {
                size: maxFileSize(MAX_IMAGE_SIZE_MB),
              },
            })}
          />
          <div className="flex flex-row">
            {image.isDirty && (
              <Button kind="text" onClick={image.reset}>
                <FormattedMessage defaultMessage="Reset" />
              </Button>
            )}
            {image.canDelete && (
              <Button kind="text" onClick={image.deleteImage}>
                <FormattedMessage defaultMessage="Delete" />
              </Button>
            )}
          </div>
        </div>
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Title" })}
        error={
          typeof errors.title?.type === "string" &&
          errorMessages.title[
            errors.title.type as keyof typeof errorMessages.title
          ]
        }
      >
        <TextInput
          aria-invalid={errors.title ? "true" : "false"}
          defaultValue={defaultValues?.title ?? ""}
          {...register("title", {
            setValueAs: (value) => value.trim(),
            required: true,
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Short Description" })}
        error={
          typeof errors.shortDescription?.type === "string" &&
          errorMessages.shortDescription[
            errors.shortDescription
              .type as keyof typeof errorMessages.shortDescription
          ]
        }
      >
        <TextInput
          aria-invalid={errors.shortDescription ? "true" : "false"}
          defaultValue={defaultValues?.shortDescription}
          {...register("shortDescription", {
            setValueAs: (value) => value.trim(),
            required: true,
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Seo meta title" })}
        error={
          typeof errors.metadata?.title?.type === "string" &&
          errorMessages.metaTitle[
            errors.metadata?.title.type as keyof typeof errorMessages.metaTitle
          ]
        }
      >
        <TextInput
          aria-invalid={errors.metadata?.title ? "true" : "false"}
          defaultValue={defaultValues?.metadata?.title ?? ""}
          {...register("metadata.title", {
            setValueAs: (value) => value.trim(),
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Seo meta description" })}
        error={
          typeof errors.metadata?.description?.type === "string" &&
          errorMessages.metaDescription[
            errors.metadata?.description
              .type as keyof typeof errorMessages.metaDescription
          ]
        }
      >
        <TextInput
          aria-invalid={errors.metadata?.description ? "true" : "false"}
          defaultValue={defaultValues?.metadata?.description ?? ""}
          {...register("metadata.description", {
            setValueAs: (value) => value.trim(),
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ defaultMessage: "Authors" })}>
        <UserAutocomplete
          query={userAutoComplete}
          placeholder={intl.formatMessage({
            defaultMessage: "username",
          })}
          onChange={({ target: { value: id } }) => {
            onAddAuthor(id);
          }}
        />
        <div className="grid grid-cols-4 my-8">
          {selectedAuthorsIds.map((authorId, index) => (
            <Suspense key={index} fallback={<EntityAvatar.Skeleton />}>
              <AuthorSelection
                authorId={authorId}
                onRemove={() => onRemoveAuthor(authorId)}
              />
            </Suspense>
          ))}
        </div>
      </FormGroup>

      <MarkdownEditorProvider value={contextValue}>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Article" })}
          error={
            typeof errors.content?.type === "string" &&
            errorMessages.content[
              errors.content.type as keyof typeof errorMessages.content
            ]
          }
        >
          <MarkdownEditor
            rows={65}
            aria-invalid={errors.content ? "true" : "false"}
          />
        </FormGroup>
      </MarkdownEditorProvider>
      <div className="pt-2 pb-8">
        <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </div>
    </form>
  );
}

interface AuthorSelectionProps {
  authorId: string;
  onRemove: () => void;
}

const AuthorSelection = ({
  authorId,
  onRemove,
}: Readonly<AuthorSelectionProps>) => {
  const data = useLazyLoadQuery<ArticleEditFormAuthorQueryType>(
    ArticleEditFormAuthorQuery,
    { id: authorId },
    { fetchPolicy: "store-or-network" },
  );
  return (
    <>
      {data?.node && (
        <div className="relative flex items-center gap-2 group">
          <EntityAvatar entity={data.node} />
          <button
            type="button"
            onClick={onRemove}
            aria-label="Remove author"
            className="absolute top-1 right-1 p-1 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <MdDelete size={ICON_SIZE} />
          </button>
        </div>
      )}
    </>
  );
};
const ICON_SIZE = 18;
const MAX_IMAGE_SIZE_MB = 1024 * 1024 * 5;
